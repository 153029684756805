* {
  box-sizing: border-box;
}
body, html {
  background-color: #CFCFCF !important;
  height: 100%;
  overflow-x: hidden;
  padding:0;
  margin:0;
  font-family: 'Britti Sans';
}

.fondo-gris {
  background-color: #d0d0d0;
}

.carta {
  max-width:13rem;
 padding:1rem;
 margin: 1rem;
 background-color: #d0d0d0;
}

.carta>img{
  margin-bottom: 1rem;

}

.modal-content{
  background:#789C48 !important;
}

.modal-dialog {
  margin: none !important;
}

.custom-modal-style {
  width: 98vw !important;
  height: 400px;
  margin: 0 auto !important;
  display: block !important;
  text-align:center !important;
  item-align: center !important;
  }


.copyright{
  text-align: right !important;
    float: right;
    padding-right: 4rem;
    font-size: .5rem;
    position: relative;
}


.copyright2{
  top: 25rem !important;
  text-align: right !important;
    float: right;
    padding-right: 4rem;
    font-size: .5rem;
    position: relative;
}

.card-header {
  background-color: rgba(0,0,0,.00)!important;
    border-bottom: none !important;
}

.card{
  border:none !important;
}

.Logo {
  padding-bottom: 1rem;

}

.logo1 {
  padding-right: 4rem;
  text-align: right;
  position: relative;
  top: 50%;
  transform: translateY(-80%);
}
 
.head-title {
  padding-bottom: 1rem;
}

.boton {
  margin-bottom: 0.5rem;
}


h1, h2, h3, h4, h5, h6 {
  font-family: "Britti Sans"
}

.head-page{
  font-weight:regular;
}




     @font-face {
      font-family: "Britti Sans";
      src: local("Britti-Sans-Medium"),
       url("./Britti-Sans-Medium.ttf") format("truetype");
       font-weight: normal;
      }
    


@media (max-width: 576px) {
    .texto{
          margin-left:1rem;
    }
     .Logo {
      width: 120%;
      position: relative;
      left: -1.5rem;
    }
    .head-title {
      font-size: .8rem !important;
    }
  .boton {
        font-family: "Britti Sans";
        font-size:1rem;
    }
    .head-page{
      font-size:3rem;
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .texto{
          margin-left:2.5rem;

    }
     .Logo {
       width: 125%;
       left:-3rem;
       position:relative;
    }
  
     .boton {
        font-family: "Britti Sans";
        font-size:1rem;
    }
     .head-title {
      font-size: .8rem !important;
         line-height: normal;
    }
    .head-page{
      font-size:3rem;
    }
}


/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
 .texto{
          margin-left:2.5rem;

    }
     .Logo {
      width: 120%;
      position: relative;
      left: -3rem;    }
     .boton {
        font-family: "Britti Sans";
        font-size:1.2rem;
    }
    
     .head-title {
      font-size: .8rem !important;
    }
    .head-page{
      font-size:4rem;
    }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
    .texto{
          margin-left:2rem;

    }

    
     .Logo {
      width: 120%;
    position: relative;
    left: -3rem;
    }
    
     .boton {
        font-family: "Britti Sans";
        font-size:1.4rem;
    }
    
     .head-title {
      font-size: .8rem !important;
    }

    .head-page{
      font-size:4.5rem;
    }
}
    
    
    /* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
     .texto{
          margin-left:2rem;

    }
     .Logo {
      width: 120%;
    position: relative;
    left: -3rem;
    }
    
    .boton {
        font-family: "Britti Sans";
        font-size:1.2rem;
    }
    
     .head-title {
           font-size: .8rem;
    }

    .head-page{
      font-size:4.5rem;
    }
    }
    
    
    
    
    
.texto {
  position: absolute;
  top: 25%;
  text-align:left;
  transform: translateY(-50%);
}
#one-main, #one-talent, #one-contact, #two, #three {
  display:inline-block;
  height: 100%;
}




#one-main {
  top:-1rem;
  position:relative;
  width:5%;
  height: 110vh;
  background-color: #939393;
  color: #433e3a;
  border: 0px solid #0067a1; 
  margin: 0 auto !important;
  text-align:center !important;
    align-items: center !important;
}


#one-talent {
  top:-1rem;
  position:relative;
  width:5%;
  height: 110vh;
  background-color: #779C47  ;
  color: #433e3a;
  border: 0px solid #0067a1; 
  margin: 0 auto !important;
  text-align:center !important;
    align-items: center !important;
}



#one-contact {
  background-color: #EA8E48 !important;
  float:left;
  width:5%;
  top:-1rem;
  position:relative;
  height: 120vh;
  color: #433e3a;
  border: 0px solid #0067a1; 
  margin: 0 auto !important;
  text-align:center !important;
    align-items: center !important;
}

#one {
  float:left;
  width:5%;
  height: 120vh;
  top:-1rem;
  position:relative;
  background-color: #3463c3;
  color: #433e3a;
  border: 0px solid #0067a1; 
  margin: 0 auto !important;
  text-align:center !important;
    align-items: center !important;
}
#two {
  width:22%;
  height: 110vh;
  background-color: #d0d0d0;
  color: #433e3a;
  display:inline-block;
}

#three {
  width:73%;
  height: 110vh;
  color: #433e3a;
  float: right;
  display:inline-block;
  padding-left: 1rem;
}

.head-title {
  text-transform: uppercase;
  font-family: "Britti Sans";
}
.TeamTinchy {
  -webkit-animation-delay: 0.5s;
}
.TeamTitchy {
  -webkit-animation-delay: 1s;
}
button {
  color:#433e3a;
  border: 1px solid #433e3a;
  background-color: transparent; 
   transition: 0.3s;
}
button:hover{
  background-color:#433e3a; 
    color:#d0d0d0;
    border: 1px solid transparent;
   transition: 0.3s;

}
    .boton {
        font-family: "Britti Sans";
    }
   
    
    .logo1 {
        /* text-align: left; */
  position: relative;
  top: 70%;
  -ms-transform: translateY(-30%);
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
    }
    
    .panealogo {
        width:14%;
    }

    .contenido-modal-talent{
      
      width:90%;
    }
.custom-modal-stylex {
  display: flex;
  background-color: #779C47 !important;
}

  
    
@keyframes fadeIn {
  0% {opacity:0.2;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0.2;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0.2;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0.2;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0.2;}
  100% {opacity:1;}
}
    
    
  @keyframes spinning {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  }

    @keyframes spinning2 {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  }
  .spinhov:hover {
    animation-name: spinning;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    /* linear | ease | ease-in | ease-out | ease-in-out */
    animation-timing-function: ease-in-out;
  }
     .girar {
    animation-name: spinning2;
    -webkit-animation-name: spinning2;
    -moz-animation-name: spinning2;
    -o-animation-name: spinning2;
    -ms-animation-name: spinning2;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
  }
     .fadex {

    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
 animation: fadeIn linear 1.5s;
  -webkit-animation: fadeIn linear 1.5s;
  -moz-animation: fadeIn linear 1.5s;
  -o-animation: fadeIn linear 1.5s;
  -ms-animation: fadeIn linear 1.5s;
  }

  .modalx {
    z-index:1 !important;
  }

  .cerrar-modal {
    z-index:0 !important;
  }
  .modal-open {
    padding-right:none !important;
  }

.modal-backdrop {
  pointer-events: none !important;}

  body {
    overflow-y: auto; /* Allows vertical scrolling by default */
  }
  
  /* Styles for laptops and desktops */
  @media (min-width: 768px) {
    body {
      overflow-y: hidden; /* Disables vertical scrolling for screens 768px and wider */
    }
  }